<template>
  <asterix-modal v-if="value" :title="value.name" closable @cancel="close">
    <template #content>
      <p
        class="text-sm text-blue-600 py-6 px-10 mb-4 cursor-pointer bg-gray-200 rounded overflow-auto"
        @click="copyText"
      >
        {{ value.pixel }}
      </p>
    </template>
    <template #footer>
      <div class="text-left">
        <save-button id="copy-pixel" text="Copy" @click="copyText" />
      </div>
    </template>
  </asterix-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import SaveButton from '@/components/atoms/SaveButton';

export default {
  name: 'CopyPixelModal',
  components: {
    SaveButton,
    AsterixModal: () => import('@/components/organisms/shared/AsterixModal'),
  },
  props: {
    /** @type PostEventTemplate */
    value: {
      type: [Object, null],
      default: () => null,
      validator: value => 'pixel' in value,
    },
  },
  data() {
    return {
      showPixelModal: false,
    };
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    copyText() {
      copyToClipboard(this.value.pixel);
      this.close();
      this.createToast(Toast.success('Success!', 'Text copied to clipboard'));
    },
    close() {
      this.$emit('cancel');
    },
  },
};
</script>
