var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c("asterix-modal", {
        attrs: { title: _vm.value.name, closable: "" },
        on: { cancel: _vm.close },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-sm text-blue-600 py-6 px-10 mb-4 cursor-pointer bg-gray-200 rounded overflow-auto",
                      on: { click: _vm.copyText },
                    },
                    [_vm._v(" " + _vm._s(_vm.value.pixel) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-left" },
                    [
                      _c("save-button", {
                        attrs: { id: "copy-pixel", text: "Copy" },
                        on: { click: _vm.copyText },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1327709112
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }